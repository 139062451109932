<template>
  <div class="homepage-3">
    <ScrollupSection />
    <div class="main overflow-hidden">
      <HeaderSection />
      <BreadcrumbSection :titulo="titulo" :descripcion="descripcion" />
      <section class="section service-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <horizontal-stepper
                :steps="demoSteps"
                @completed-step="completeStep"
                @active-step="isStepActive"
                @stepper-finished="alert"
                class="m-5"
                locale="es"
              >
              </horizontal-stepper>
            </div>
          </div>
        </div>
      </section>
      <FooterSection />
      <ModalSearchSection />
      <ModalRegistro />
      <ModalMenuSection />
      <ModalTerms/>
      <ModalCapcha :titulo="tituloFormato"  :nombre-archivo="tituloArchivo" :url="url" :tipo-formato="3"/>
    </div>
  </div>
</template>

<script>
import { analytics } from "../../../firebase";

import { mapMutations } from "vuex";

//general components
import BreadcrumbSection from "../../Breadcrumb/Services";
import ScrollupSection from "../../Scrollup/Scrollup";
import HeaderSection from "../../Header/HeaderOne";
import FooterSection from "../../Footer/FooterOne";

//Modal comoponents
import ModalSearchSection from "../../Modal/ModalSearch/ModalSearch";
import ModalRegistro from "../../Modal/ModalSearch/ModalRegistro";
import ModalMenuSection from "../../Modal/ModalMenu/ModalMenu";
import ModalTerms from '../../Modal/ModalTerms/ModalTerms';
import ModalCapcha from '../../Modal/ModalCaptcha/ModalCapcha';

//Forms components
import HorizontalStepper from "vue-stepper";
import Pruebas from "../../../components/forms/VG/Pruebas";
import GeneralesRepresentante from "../../../components/forms/GeneralesRepresentante";
import Generals from "../../../components/forms/Generals";
import Demandado from "../../forms/DVG/Demandado";
import Complement from "../../../components/forms/VG/Complement";
import MedidasCaute from "../../forms/DVG/MedidasCaute";

// import MedidasProteccion from "../../forms/DVG/MedidasProteccion";
import MedidasProteccionAPE from "../../forms/APE/MedidasProteccionAPE";

export default {
  name: "DenunciaViolenciaGenero",
  metaInfo: {
    title:
      "Electoral Tech & Legal | Denuncia en materia de Violencia Política contra las mujeres en razón de género",
    description:
      "Crea y genera de manera simple tu formato de denuncia en materia de Violencia Política contra las mujeres en razón de género",
  },
  components: {
    HeaderSection,
    ScrollupSection,
    BreadcrumbSection,
    FooterSection,

    ModalSearchSection,
    ModalRegistro,
    ModalMenuSection,
    ModalTerms,
    ModalCapcha,

    HorizontalStepper,
  },
  mounted() {
    analytics.logEvent("formatoComenzado", { name: "DenunciaViolenciaGenero" });
    window.$("#modalTerminos").modal("show");
    this.setTipoFormato(1);
  },
  data() {
    return {
      titulo:
        "Denuncia en materia de Violencia Política contra las mujeres en razón de género",
      tituloFormato: "Denuncia de violencia politica en razón de género",
      tituloArchivo: "denuncia-politica-en-razon-genero.pdf",
      descripcion: "¿Necesitas denunciar un acto de violencia política en razón de género? Olvídate de pagar honorarios y hazlo tú misma.",
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/generarDVPRG",
      urlLocal:
        "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/generarDVPRG",
      demoSteps: [
        {
          icon: "person",
          name: "first",
          title: "Datos del representante",
          // subtitle: "Subtitle sample",
          component: GeneralesRepresentante,
        },
        {
          icon: "person",
          name: "sencond",
          title: "Datos del accionante",
          // subtitle: "Subtitle sample",
          component: Generals,
        },
        {
          icon: "person",
          name: "third",
          title: "Datos del demandado",
          // subtitle: "Subtitle sample",
          component: Demandado,
        },
        {
          icon: "announcement",
          name: "fourth",
          title: "Actos denunciados",
          // subtitle: "Subtitle sample",
          component: Complement,
        },
        {
          icon: "announcement",
          name: "fifth",
          title: "Medidas cautelares",
          // subtitle: "Subtitle sample",
          component: MedidasCaute,
        },
        {
          icon: "announcement",
          name: "sixth",
          title: "Medidas Protección",
          // subtitle: "Subtitle sample",
          component: MedidasProteccionAPE,
        },
        {
          icon: "speaker_notes",
          name: "seventh",
          title: "Pruebas",
          // subtitle: "Subtitle sample",
          component: Pruebas,
          completed: false,
        },
      ],
    };
  },
  computed: {
    url() {
      return process.env.NODE_ENV == "development"
        ? this.urlLocal
        : this.urlProd;
    },
  },
  methods: {
    ...mapMutations('generals', ['setTipoFormato']),
    completeStep(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      });
    },
    errorMessage(error) {
      this.$swal({
        icon: "error",
        title: error,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
      });
    },
    // Executed when @active-step event is triggered
    isStepActive(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          if (step.completed === true) {
            step.completed = false;
          }
        }
      });
    },
    alert() {
      window.$("#ModalCaptcha").modal("show");
    },
  },
};
</script>
<style>
.stepper-box .content {
  overflow: visible !important;
}
.v-select {
  /* height: calc(1.5em + 0.75rem + -2px); */
  box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
  border: none;
}

.vs--searchable .vs__dropdown-toggle {
  border: none;
}
</style>
