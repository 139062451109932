<template>
  <div style="margin: 10px">
    <form>
      <!-- <div class="row justify-content-md-center">
        <div class="col-8 text-center">
          <label for="">¿Es funcionario político?</label>
          <br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              v-model="$v.form.funcionario.$model"
              name="promueve"
              id="promueve1"
              value="1"
              checked
            />
            <label class="form-check-label" for="promueve1"> Si </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              funcionario
              v-model="$v.form.funcionario.$model"
              name="promueve"
              id="promueve2"
              value="2"
            />
            <label class="form-check-label" for="promueve2"> No </label>
          </div>
        </div>
      </div> -->
      <div class="row justify-content-md-center" >
        <div class="mb-3 col-lg-4 col-xs-12">
          <label
            for="cargosPopulares"
            class="form-label"
            aria-label="Cargo de elección popular"
          >
            Cargo de elección popular *</label
          >
          <v-select
            :options="cargosEleccionPopularGnerales"
            id="cargosPopulares"
            v-model="$v.form.cargosEleccionPopular.$model"
            label="texto"
            placeholder="Selecciona"
            aria-label="Cargos de eleccion popular"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!form.cargosEleccionPopular"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <slot name="no-options">No se encontraron coincidencias.</slot>
          </v-select>
        </div>

        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="dependencia" class="form-label" aria-label="Dependencia">
            Dependencia</label
          >
          <input
            type="text"
            id="dependencia"
            class="form-control"
            :class="{ error: $v.form.dependencia.$error }"
            v-model.trim="$v.form.dependencia.$model"
            aria-label="dependencia"
          />
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="nombres" class="form-label" aria-label="Nombres">
            Nombres *</label
          >
          <input
            type="text"
            id="nombres"
            class="form-control"
            :class="{ error: $v.form.nombres.$error }"
            v-model.trim="$v.form.nombres.$model"
            aria-label="nombres"
          />
        </div>
        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="appPat" class="form-label" aria-label="Apellido paterno">
            Apellido Paterno
          </label>
          <input
            type="text"
            id="appPat"
            class="form-control"
            :class="{ error: $v.form.appPat.$error }"
            v-model.trim="$v.form.appPat.$model"
            aria-label="Apellido paterno"
          />
        </div>
        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="appMat" class="form-label" aria-label="Apellido materno">
            Apellido Materno
          </label>
          <input
            type="text"
            id="appMat"
            class="form-control"
            :class="{ error: $v.form.appMat.$error }"
            v-model.trim="$v.form.appMat.$model"
            aria-label="Apellido materno"
          />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import { required } from "vuelidate/lib/validators";
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "Demandado",
  mounted() {
    /* console.log("mounted", this.$v.$invalid); */
    if (!this.$v.$invalid) {
      this.$emit("can-continue", { value: true });
    } else {
      //this.verTerminos();
      this.$emit("can-continue", { value: false });
    }
  },
  activated: function () {
    /* console.log("activate"); */
    if (!this.$v.form.$invalid) {
      // console.log("activate can");
      this.$emit("can-continue", { value: true });
    } else {
      // console.log("activate cant");
      this.$emit("can-continue", { value: false });
    }
  },
  data() {
    return {
      form: {
        nombres: "",
        appPat: "",
        appMat: "",
        cargosEleccionPopular: null,
        dependencia: null,
      },
    };
  },
  validations() {
    return {
        form: {
          nombres: {
            required,
          },
          appPat: {
            required,
          },
          appMat: {
            required,
          },
          cargosEleccionPopular: {
            required,
          },
          dependencia: {
            required,
          }
        },
      };
  },
  computed: {
    ...mapGetters("generals", ["getrelacionesSocialM"]),
    ...mapState("generals", [
      "organosJurisdiccionales",
      "cargosEleccionPopularGnerales",
    ]),
  },
  methods: {
    ...mapMutations("contenido", ["setDemandadoData"]),
  },
  watch: {
    $v: {
      handler: function (val) {
        // console.log("es invalido cambio.... ", val.$invalid);
        if (!val.$invalid) {
          this.$emit("can-continue", { value: true });
          const demandado = { ...this.form };
          this.setDemandadoData(demandado);
        } else {
          this.$emit("can-continue", { value: false });
        }
      },
      deep: true,
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
        // this.setFormatoDatos(this.form)
      }
    },
  },
};
</script>