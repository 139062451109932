<template>
  <div style="margin: 10px">
    <div class="row">
      <div class="col-12 mb-3 mt-3">
        <div class="alert alert-secondary" role="alert">
          De acuerdo a las consideraciones que han sido narradas en la presente
          denuncia, solicito se decreten de inmediato las siguientes medidas
          cautelares:
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="sexo" class="form-label">
            Selecciona una institución
          </label>
          <v-select
            :options="institucionesJusticia"
            id="sexo"
            v-model="$v.form.instituto.$model"
            aria-label="sexo"
            label="texto"
            multiple
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!$v.form.instituto.$model"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <slot name="no-options">No se encontraron coincidencias.</slot>
          </v-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <Editor tipoMedidas="medidasCautelares"></Editor>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from "../../Editor/Editor";
import { mapState, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "MedidasCaute",
  props: ["clickedNext", "currentStep"],
  components: {
    Editor,
  },
  data() {
    return {
      form: {
        instituto: [],
      },
    };
  },
  activated: function () {
    console.log("activate");
    // console.log(this.getMedidasCautelares);
    if (
      this.form.instituto.length > 0 /* &&
      this.getMedidasCautelares != ''  && 
      this.getMedidasCautelares != undefined &&
      this.getMedidasCautelares != "<p></p>" &&
      this.getMedidasCautelares != "<h3></h3>" */
    ) {
      // console.log("activate can");
      this.$emit("can-continue", { value: true });
    } else {
      // console.log("activate cant");
      this.$emit("can-continue", { value: false });
    }
  },
  computed: {
    ...mapState("generals", ["institucionesJusticia"]),
    // ...mapGetters("contenido", ["getMedidasCautelares"]),
  },
  methods: {
    ...mapMutations("contenido", ["setFormatoDatos"]),
  },
  validations: {
    form: {
      instituto: {
        required,
      },
    },
  },
  watch: {
    $v: {
      handler: function (val) {
        // console.log("es invalido cambio.... ", val.$invalid, this.medidasRiesgos);
        if (
          !val.$invalid /* &&
          this.getMedidasCautelares != "<p></p>" &&
          this.getMedidasCautelares != "<h3></h3>" &&
          this.getMedidasCautelares != "" */
        ) {
          this.$emit("can-continue", { value: true });
          // console.log("can continue watch");
          this.setFormatoDatos({ institutoCautelares: this.form.instituto });
        } else {
          this.$emit("can-continue", { value: false });
        }
      },
      deep: true,
    },
    /* getMedidasCautelares: {
      handler: function (nuevoValor) {
        if (nuevoValor != "<p></p>" && nuevoValor != "<h3></h3>" && nuevoValor != "" ) {
          this.$emit("can-continue", { value: true });
          this.setFormatoDatos({ institutoCautelares: this.form.instituto });
        } else {
          this.$emit("can-continue", { value: false });
        }
      },
    }, */
  },
};
</script>
<style scoped>
.alert-secondary {
  color: #e2e3e5;
  background-color: #1c1c38;
  border-color: #1c1c38;
}
.selectMargin {
  margin-bottom: 200px;
}

.error {
  border: 1px solid #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
</style>