<template>
  <div style="margin: 10px">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="alert alert-secondary" role="alert">
          Señalar las medidas que requiera se decreten a efecto de prevenir
          mayores daños, entre otros
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-3">
        <ul class="list-group">
          <li class="list-group-item active">
            Selecciona las medidas que consideres pertinentes.
          </li>
          <li
            class="list-group-item"
            v-for="motivo in medidasProteccion"
            :key="motivo.id"
          >
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                :id="`check${motivo.id}`"
                :value="motivo"
                v-model="$v.form.motivosSelected.$model"
              />
              <label class="form-check-label" :for="`check${motivo.id}`">
                {{ motivo.texto }}
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "MedidasProteccionAPE",
  data() {
    return {
      form: {
        motivosSelected: [],
      },
    };
  },
  activated: function () {
    /* console.log("activate"); */
    if (this.form.motivosSelected.length > 0) {
      // console.log("activate can");
      this.$emit("can-continue", { value: true });
    } else {
      // console.log("activate cant");
      this.$emit("can-continue", { value: false });
    }
  },
  computed: {
    ...mapState("generals", ["medidasProteccion"]),
  },
  methods: {
    ...mapMutations("contenido", ["setFormatoDatos"]),
  },
  validations: {
    form: {
      motivosSelected: { required },
    },
  },
  watch: {
    $v: {
      handler: function (val) {
        // console.log("es invalido cambio.... ", val.$invalid);
        if (!val.$invalid) {
          this.$emit("can-continue", { value: true });
          this.setFormatoDatos(this.form);
        } else {
          this.$emit("can-continue", { value: false });
        }
      },
      deep: true,
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
      }
    },
  },
};
</script>
<style scoped>
.list-group-item.active {
  z-index: 2;
  color: #191919;
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.alert-secondary {
  color: #e2e3e5;
  background-color: #1c1c38;
  border-color: #1c1c38;
}

.form-control {
  height: calc(1.5em + 0.75rem + 2px);
}
.vs__dropdown-toggle {
  border: 1px solid #eee;
  height: calc(1.5em + 0.75rem + 2px);
}
.v-select {
  height: calc(1.5em + 0.75rem + 2px);
  box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
}
.error {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>